@import '../../../../theme/breakpoints';

.seo-heading {
  display: inline-block;
  height: 100%;
  margin: 0;
  z-index: 200;
}

.header-primary {
  background-color: var(--color-com-header-primary-nav-container-bg);
  height: var(--size-ref-13, 56px);

  @media #{$large} {
    height: var(--size-ref-16, 80px);
  }
}

.header-secondary {
  background-color: var(--color-sem-surface-neutral-subtle);
  height: 47px;
}

.header {
  align-items: center;
  border-bottom: var(--border-width-com-header-primary-nav-border) solid
    var(--color-com-header-primary-nav-container-border-color);
  display: flex;
  height: var(--size-ref-13, 56px);
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--wrapper-xs);
  padding-right: var(--space-sem-padding-small);
  position: sticky;
  top: -1px;
  z-index: 9997;

  &.navigator-only-header {
    background-color: var(--color-com-header-primary-nav-surface);
  }

  > a {
    display: inline-block;
    height: 100%;
  }

  .header-socials {
    ul {
      flex-wrap: nowrap;
    }
  }

  &.extended-header {
    max-width: 100vw;

    @media #{$medium} {
      padding-right: 120px;
    }

    &.with-burger {
      @media #{$xlarge} {
        padding: 0 calc((100vw - (100vw - 100%) - var(--wrapper-xl)) / 2);
      }
    }

    @media #{$large} {
      padding: 0 120px 0 0;
    }

    @media #{$xlarge} {
      padding: 0 calc((100vw - (100vw - 100%) - var(--wrapper-xl)) / 2);
    }
  }

  @media #{$small} {
    margin-bottom: 24px;
    max-width: calc(100vw - 2 * 20px);

    > section {
      margin: 0 0 0 auto;
    }
  }

  @media #{$medium} {
    margin: 0 0 24px;
    max-width: var(--wrapper-l);
    padding-right: unset;
    width: 100%;
  }

  @media #{$large} {
    height: var(--size-ref-16, 80px);
    padding-left: 0;
  }

  @media #{$xlarge} {
    margin: 0 auto 24px;
    max-width: var(--wrapper-xl);
    top: -1px;
  }
}

.secondary-nav-container {
  left: 0;
  position: absolute;
  right: 0;
  top: 56px;

  @media #{$large} {
    top: 80px;
  }
}

.mastheadwebstrip-container {
  background-color: var(--color-sem-background-neutral-default);
  border-left: var(--border-width-sem-thin) solid var(--color-sem-border-neutral-subtle);
  border-right: var(--border-width-sem-thin) solid var(--color-sem-border-neutral-subtle);
  height: 34px;
  left: 0;
  position: absolute;
  right: 0;
  top: 56px;

  @media #{$large} {
    height: 33px;
    max-width: var(--wrapper-l);
    top: 80px;
  }

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}

.secondary-nav-container ~ .mastheadwebstrip-container {
  @media #{$small} {
    top: 89px;

    &.is-experiment {
      top: 56px;
    }
  }

  @media #{$large} {
    top: 110px;

    &.is-experiment {
      top: 80px;
    }
  }
}
