@import '../../../../theme/breakpoints';

.gdpr-link {
  align-items: center;
  background-color: #3c5161;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
  pointer-events: auto;
  text-decoration: none;
  z-index: 1000;

  @media #{$medium} {
    margin-right: 10px;
  }

  & img {
    height: 17px;
    margin-right: 5px;
    width: 16px;
  }
}
