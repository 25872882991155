@import '../../../../theme/breakpoints';

.footer-container {
  align-items: center;
  background-color: var(--color-ref-neutral-1000);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--size-ref-7);
  justify-content: center;
  margin: 0 auto;
  max-width: var(--wrapper-xs);
  max-width: calc(100vw - 2 * 20px);
  padding: var(--size-ref-7) var(--size-ref-5);
  position: relative;

  h5 {
    margin-left: 0;
  }

  @media #{$medium} {
    margin-left: 0;
    margin-right: 0;
    max-width: var(--wrapper-l);
    width: 100%;
  }

  @media #{$large} {
    margin-left: 0;
    margin-right: 0;
  }

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}

.kite-mark {
  align-items: center;
  border-bottom: var(--border-width-sem-thin) solid var(--color-ref-neutral-500);
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 0 auto;
  margin-left: 0;
  max-width: var(--wrapper-xs);
  padding-bottom: var(--space-ref-7);
  width: 100%;

  a {
    margin: 0 10px;

    img {
      object-fit: cover;
    }
  }
}

.socials {
  align-items: center;
  border-bottom: var(--border-width-sem-thin) solid var(--color-ref-neutral-500);
  display: flex;
  flex-direction: column;
  padding: var(--space-ref-0) var(--space-ref-7) var(--space-ref-7) var(--space-ref-7);
  width: 100%;

  .socials-headline {
    color: var(--color-ref-neutral-200);
    font-size: var(--text-typeset-font-size-s);
    font-weight: var(--text-typeset-font-weights-normal-400);
  }

  > section {
    margin: 0 auto;
  }
}
