/* stylelint-disable selector-class-pattern */
.regwall-box__content > .check-email > .check-email__text--title {
  line-height: 32px;
}

div.modal > div.modal__backdrop:has(div.App.regwall-box) {
  z-index: 2147483646;
}

.jw-flag-floating div.jw-wrapper {
  z-index: 2147483646;
}
