@import '../../../../theme/breakpoints';

.nav-curtain {
  background-color: var(--color-sem-surface-neutral-subtle);
  color: black;
  left: 0;
  margin: auto;
  overflow: auto;
  position: sticky;
  top: calc(var(--size-ref-13, 56px) - 1px);
  z-index: 1001;

  @media #{$small} {
    max-width: var(--wrapper-xs);
  }

  @media #{$medium} {
    margin-right: 120px;
    max-width: var(--wrapper-l);
  }
}

.inner-close {
  bottom: 2rem;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  text-decoration: underline;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    list-style: none;
  }
}
