@import '../../../theme/breakpoints';

#breaking-banner {
  font-family: var(--text-typeset-font-families-sem-brand);
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  margin: 0 0 0 -20px;
  max-width: 100vw;
  padding: var(--space-com-breaking-banner-inset-padding);
  pointer-events: auto;
  width: 100vw;

  @media #{$medium} {
    margin: 0 102px 0 0;
    max-width: calc(var(--wrapper-l) - 16px);
  }

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}

.breaking-container {
  background-color: var(--color-com-breaking-banner-bg);
  border: var(--border-width-com-breaking-banner-border) solid var(--color-com-breaking-banner-border);
  border-radius: var(--border-radius-com-breaking-banner-radius);
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 8px;
  width: 100%;
}

.breaking-info {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  padding-top: 2px;

  &:active,
  &:link,
  &:visited {
    color: var(--color-com-breaking-banner-fg);
    text-decoration: none;
  }

  &:hover {
    color: var(--color-com-breaking-banner-fg);
    text-decoration: underline;
  }

  span {
    margin-bottom: 1px;
  }
}

.breaking-prefix {
  display: inline;
  font-size: var(--text-typeset-font-size-l);
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin: 0 16px 0 8px;
  padding: 0;
}

.breaking-headline {
  display: inline;
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  padding: 0;

  svg {
    max-height: 22px;
    min-width: 22px;
    padding-bottom: 2px;
    vertical-align: middle;
  }
}

.breaking-close {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  height: 24px;
  margin: 0 0 0 auto;
  min-width: 24px;
  padding: 0;
  width: 24px;
}
