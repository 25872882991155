@import '../../../../theme/breakpoints';

.inner-close {
  align-items: center;
  background-color: initial;
  border: none;
  color: var(--color-sem-surface-primary-default);
  cursor: pointer;
  display: flex;
  font-size: var(--text-typeset-font-size-m, 18px);
  font-weight: 600;
  gap: var(--space-ref-2, 4px);
  letter-spacing: var(--text-typeset-letter-spacing-normal, 0);

  & svg {
    height: 24px;
    width: 24px;
  }
}

.desktop-curtain {
  align-items: stretch;
  background-color: var(--color-sem-surface-neutral-default);
  box-shadow: 0 4px 4px 0 rgb(20 21 21 / 10%);
  display: flex;
  flex-direction: column;
  gap: var(--space-ref-7);
  height: calc(100vh - var(--size-ref-16, 80px) - 33px - 10px);
  justify-content: flex-start;
  margin: 0 auto;
  overflow: auto;
  padding: var(--space-sem-padding-medium) var(--space-ref-14);
  position: absolute;
  top: calc(var(--size-ref-16, 80px));
  width: 100%;
  z-index: 1001;

  @media #{$xlarge} {
    width: var(--wrapper-xl);
  }
}

.column-list {
  align-items: stretch;
  display: flex;
  flex: 1;
  justify-content: space-between;
  list-style-type: none;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.column {
  flex: 1;
  margin: 0 15px;
  text-transform: uppercase;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link-container {
  height: var(--size-ref-9);
}

.primary-link {
  align-items: center;
  color: var(--color-sem-link-primary-default);
  display: block;
  font-size: var(--text-typeset-font-size-m);
  font-weight: bold;
  gap: 3px;
  margin: var(--space-ref-2) 0;
  text-decoration: none;
  text-transform: uppercase;

  & > svg {
    color: var(--color-sem-surface-primary-default);
    margin-right: -24px;
  }
}

.menu-grid {
  background-color: var(--color-sem-surface-neutral-subtle);
  border-bottom: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  border-top: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style-type: none;
  margin: 0 -68px;
  padding: var(--space-sem-padding-medium) var(--space-ref-14);

  li {
    margin: 0;

    a {
      text-decoration: none;
    }
  }
}

.sub-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sub-link {
  align-items: center;
  color: var(--color-sem-link-neutral-default);
  display: flex;
  font-size: var(--text-typeset-font-size-2xs);
  font-weight: bold;
  height: var(--size-ref-9, 32px);
  margin: var(--space-sem-gap-none) 0;
  text-decoration: none;
  text-transform: uppercase;
}

.bottom-container {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}
