@import '../../../../theme/breakpoints';

.gutter {
  align-items: center;
  display: none; // Default display state is none (will be overridden later)
  display: flex;
  height: 100%;
  overflow: hidden;
  position: fixed;
}

@media #{$small} {
  .left {
    left: 0;
    right: auto;
  }

  .right {
    left: auto;
    right: 0;
  }

  .gutter {
    width: calc((100vw - (100vw - 100%) - var(--wrapper-xs)) / 2);
  }
}

@media #{$medium} {
  .right {
    width: calc((100vw - (100vw - 100%) - var(--wrapper-l)));
  }

  .left {
    display: none; // Only hide on medium screens
  }

  .gutter {
    width: calc((100vw - (100vw - 100%) - var(--wrapper-l)) / 2);
  }
}

@media #{$xlarge} {
  .left {
    display: flex; // Ensure .left is visible on xlarge screens
    left: 0;
    right: auto;
  }

  .right {
    display: flex; // Ensure .right is visible on xlarge screens
    left: auto;
    right: 0;
  }

  .gutter {
    width: calc((100vw - (100vw - 100%) - var(--wrapper-xl)) / 2);
  }
}
