@import '../../../../theme/breakpoints';

.masthead {
  aspect-ratio: unset;
  height: 100%;
  margin-left: var(--space-ref-12);
  margin-right: var(--space-sem-padding-small);
  max-width: min-content;
  width: auto;

  &.no-burger {
    margin-left: var(--space-sem-padding-small);
    @media #{$medium} {
      margin-left: var(--space-sem-padding-medium);
    }
  }

  @media #{$large} {
    height: var(--size-ref-16);
    margin: 0 var(--space-sem-padding-medium);
    max-height: 100%;
  }
}
