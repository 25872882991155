@import '../../../../theme/breakpoints';

.unregistered-avatar {
  height: var(--size-ref-11);
  width: var(--size-ref-11);
}

.registered-avatar {
  align-items: center;
  background: var(--color-com-header-primary-nav-avatar-bg);
  border-radius: 50%;
  color: var(--color-com-header-primary-nav-avatar-fg);
  display: flex;
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: 700;
  height: var(--size-ref-11);
  justify-content: center;
  line-height: 140%;
  width: var(--size-ref-11);
}

.avatar-container {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: var(--size-ref-11);
  justify-content: center;
  margin-left: var(--size-ref-5);
  outline: none;
  padding: 0;
  position: relative;
  width: var(--size-ref-11);
  z-index: 9999;

  @media #{$medium} {
    margin-right: var(--space-sem-padding-small);
  }

  @media #{$xlarge} {
    margin-right: 10px;
  }
}
