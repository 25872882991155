.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: var(--text-typeset-font-size-m);
  gap: var(--space-ref-4);
  height: 128px;
  padding: 24px 16px;
}

.row {
  align-items: center;
  flex-direction: row;
  gap: var(--space-ref-3);
  height: var(--space-ref-10);
}

.title {
  color: var(--color-sem-text-neutral-default);
  font-weight: var(--text-typeset-font-weights-normal-400);
}

.list {
  display: flex;
  gap: var(--space-ref-7);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.territory {
  align-items: center;
  display: flex;
  height: var(--space-ref-9);
  padding-right: var(--space-ref-5);

  &:not(:last-child) {
    border-right: var(--border-width-ref-2) solid var(--color-sem-border-neutral-default);
  }
}

.label {
  margin-right: var(--space-ref-2);
}
