@import '../../../../theme/breakpoints';

.cipa-info {
  color: #fff;
  line-height: 1.5em;
  margin: 20px 0;

  > a {
    color: #0097a7;
  }
}

.cipa-accept-button {
  background: #fff;
  border: 0;
  border-radius: 2px;
  color: #3c5161;
  cursor: pointer;
  float: right;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;

  @media #{$medium} {
    width: 30%;
  }

  &:focus-visible {
    outline: var(--border-width-ref-3) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.cipa-logo {
  display: flex;
  justify-content: center;

  > img {
    max-width: 130px;

    @media #{$medium} {
      max-width: 150px;
    }
  }
}

.cipa-dialog {
  background-color: #3c5161;
  border: none;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0;
  max-width: 100%;
  padding: 20px;
  position: fixed;
  top: 100%;
  transform: translateY(-100%);
  z-index: 2001;

  &::backdrop {
    background-color: #212934bf;
  }
}
