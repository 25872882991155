@import '../../../../theme/breakpoints';

.primary-footer {
  border-bottom: var(--border-width-sem-thin) solid var(--color-ref-neutral-500);
  display: grid;
  gap: var(--size-ref-7);
  grid-template-columns: repeat(2, 1fr);
  list-style-type: none;
  margin: 0;
  padding: 0 0 var(--size-ref-7);
  width: 100%;

  li {
    border: none;
    margin: 0;

    &:nth-child(even) {
      justify-self: self-end;
    }
  }

  a {
    color: var(--color-ref-neutral-100) !important;
    text-transform: uppercase;
  }

  @media #{$medium} {
    grid-template-columns: repeat(3, 1fr);

    li:nth-child(3n + 1) {
      justify-self: start;
    }

    li:nth-child(3n + 2) {
      justify-self: center;
    }

    li:nth-child(3n + 3) {
      justify-self: end;
    }
  }

  @media #{$large} {
    grid-template-columns: repeat(4, 1fr);

    li:not(:nth-child(4n)) {
      justify-self: initial;
    }

    li:nth-child(4n + 2),
    li:nth-child(4n + 3) {
      justify-self: center;
    }

    li:nth-child(4n) {
      justify-self: self-end;
    }
  }
}
