.heading {
  align-items: center;
  border-top: var(--border-width-sem-thin) solid var(--color-sem-border-neutral-subtle);
  display: flex;
  height: var(--size-ref-12);
  justify-content: space-between;
  padding: 0 var(--space-ref-5, 16px);

  & > span > a {
    align-items: center;
    color: var(--color-sem-link-primary-default);
    display: inline-flex;
    font-size: var(--text-typeset-font-size-m, 16px);
    font-weight: var(--text-typeset-font-weights-normal-600);
    justify-content: flex-end;
    text-transform: uppercase;

    & > span > svg {
      margin-left: var(--space-ref-2, 4px);
    }
  }
}

.sub-menu {
  border-top: var(--border-width-sem-thin) solid var(--color-sem-border-neutral-subtle);
  display: grid;
  gap: --space-sem-gap-2xsmall;
  list-style: none;
  padding: var(--space-ref-3, 8px) var(--space-ref-9, 32px);
  text-transform: uppercase;

  li {
    display: grid;
    height: var(--size-ref-9);
  }

  & > li > span > a {
    color: var(--color-sem-link-neutral-default);
    display: inline-flex;
    font-size: var(--text-typeset-font-size-2xs, 11px);
    font-weight: var(--text-typeset-font-weights-normal-600);
    padding: 0 2px;
    width: fit-content;

    &:visited {
      color: var(--color-sem-link-neutral-default);
    }
  }
}

.sub-menu-toggle {
  background-color: var(--color-sem-surface-neutral-subtle);
  border: 0;
  cursor: pointer;
}
