@import '../../../../theme/breakpoints';

.floating-footer {
  align-items: flex-start;
  bottom: -1px;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0 auto;
  max-width: var(--wrapper-xs);
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 2000;

  a:first-child {
    pointer-events: auto;
  }

  > div {
    > div[class*='commercial-box-child-style'] {
      margin-bottom: 0;
    }
  }

  @media #{$small} {
    > div[class*='commercial-box-style'] {
      background-color: var(--color-sem-icon-on-primary-default);
      pointer-events: auto;

      > div[class*='commercial-box-child-style'] {
        width: 320px;

        > div[class*='commercial-gpt-style'] {
          height: 100px;
          margin-bottom: 1px;

          > div[class*='collapse'] {
            height: 52px;
          }

          > div[class*='collapse-transition'] {
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }

  @media #{$medium} {
    align-items: flex-end;

    > div[class*='commercial-box-style'] {
      display: none;
    }
  }
}

@mixin responsive-height {
  @media (max-width: #{$m - 1}px) {
    background-color: var(--color-ref-neutral-1000);
  }

  @media #{$medium} {
    display: none;
  }
}

.scroll {
  margin: 0 auto;
  max-width: var(--wrapper-xs);

  &.placeholder {
    @include responsive-height;
  }
}
