.nav-item-wrapper {
  background-color: var(--color-com-header-primary-nav-menu-item-bg-default);
  cursor: pointer;
  padding: 0 var(--space-ref-4);
  transition: background-color 0.3s ease;

  a {
    color: var(--color-com-header-primary-nav-menu-item-fg-default);

    span svg {
      color: var(--color-com-header-primary-nav-menu-item-icon-default);
    }
  }

  &:hover {
    background: var(--color-com-header-primary-nav-menu-item-bg-hover);

    a {
      color: var(--color-com-header-primary-nav-menu-item-fg-hover);

      span svg {
        color: var(--color-com-header-primary-nav-menu-item-icon-hover);
      }
    }
  }

  &:not(:hover).active {
    background-color: var(--color-com-header-primary-nav-menu-item-bg-active);

    a {
      color: var(--color-com-header-primary-nav-menu-item-fg-active);

      span svg {
        color: var(--color-com-header-primary-nav-menu-item-icon-active);
      }
    }
  }
}

.nav-link {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: var(--text-typeset-font-size-2xs);
  font-weight: 600;
  height: 100%;
  line-height: 160%; /* 25.6px */
  text-decoration: none;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.icon-wrapper {
  margin-left: 4px;
}
