@import '../../../../theme/breakpoints';

.avatar-dropdown {
  background: var(--color-sem-surface-neutral-default);
  border: solid 1px var(--color-sem-border-neutral-subtle);
  border-radius: var(--border-radius-ref-2);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
  position: absolute;
  right: -16px;
  text-align: center;
  top: 48px;
  width: 250px;
  z-index: 1;

  @media #{$large} {
    top: 60px;
  }

  @media #{$xlarge} {
    right: -9px;
  }

  ul {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--text-typeset-font-size-s);
    font-style: normal;
    font-weight: 700;
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    line-height: normal;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    button {
      align-items: center;
      align-self: stretch;
      background: none;
      border: none;
      border-top: solid 1px var(--color-sem-border-neutral-subtle);
      color: inherit;
      cursor: pointer;
      display: flex;
      font: inherit;
      gap: var(--space-sem-gap-2xsmall);
      height: 46px;
      justify-content: center;
      padding: var(--space-ref-3) var(--space-ref-5);
      width: 100%;

      &:hover {
        background-color: var(--color-sem-surface-neutral-hovered);
      }

      &:disabled {
        background-color: var(--color-sem-text-neutral-disabled);
      }

      &:active {
        background-color: var(--color-sem-surface-neutral-pressed);
      }
    }

    li:not(:nth-last-of-type(1)) a {
      align-items: center;
      align-self: stretch;
      box-sizing: border-box;
      color: inherit;
      display: flex;
      gap: var(--space-sem-gap-2xsmall);
      height: 46px;
      justify-content: center;
      padding: var(--space-ref-3) var(--space-ref-5);
      text-decoration: none;
    }
  }
}
