@import '../../../../theme/breakpoints';

.burger-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--color-com-header-primary-nav-container-bg);
  border: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: var(--space-ref-4, 12px);
  position: absolute;
  width: var(--size-ref-12);

  // :last-child refers to .secondary-nav-container from Header.tsx with enough specificity
  &[aria-expanded='true'] ~ :last-child {
    top: 56px;
  }

  @media #{$large} {
    display: none;
  }
}

.burger-menu {
  border-bottom: var(--border-width-ref-1) solid var(--color-com-header-primary-nav-hamburger-icon);
  border-top: var(--border-width-ref-1) solid var(--color-com-header-primary-nav-hamburger-icon);
  height: 12.48px;
  position: relative;
  width: 18.72px;

  &::after {
    background: var(--color-com-header-primary-nav-hamburger-icon);
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 37%;
    width: 100%;
  }
}

.burger-close-btn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;
}
