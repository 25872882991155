@import '../../../../theme/breakpoints';

.affiliate-sticky-bar-container {
  background-color: var(--color-ref-affiliate-500);
  color: var(--color-sem-text-affiliate-default);
  display: none;
  flex-flow: row wrap;
  gap: var(--space-sem-gap-small);
  margin: 0 auto;
  max-width: var(--wrapper-xs);
  padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-medium);
  pointer-events: auto;
  width: 100%;

  &.sticky {
    display: flex;
  }

  .affiliate-sticky-bar-img {
    display: none;
  }

  .affiliate-sticky-bar-left,
  .affiliate-sticky-bar-right {
    display: flex;
  }

  .affiliate-sticky-bar-left {
    align-items: flex-start;
    flex: 1;
    flex-direction: column-reverse;
  }

  .affiliate-sticky-bar-right {
    align-items: center;
    flex: 0 129px;
    justify-content: end;
  }

  .affiliate-sticky-bar-price {
    flex: 0;
    font-size: var(--text-typeset-font-size-m);
    font-weight: var(--typography-body-md-700-font-weight);
    line-height: var(--typography-body-md-700-line-height);
  }

  .affiliate-sticky-bar-title {
    flex: 1;
    font-family: inherit;
    font-size: var(--typography-caption-md-400-font-size);
    font-weight: var(--typography-caption-md-400-font-weight);
    line-height: var(--typography-body-md-700-line-height);
  }

  .affiliate-sticky-bar-mob-btn {
    display: flex;
  }

  .affiliate-sticky-bar-btn {
    display: none;
  }

  .affiliate-sticky-bar-mob-btn,
  .affiliate-sticky-bar-btn {
    align-items: center;
    background-color: var(--color-sem-surface-neutral-default);
    border-radius: var(--border-radius-sem-cta-max);
    color: var(--color-sem-icon-neutral-default);
    font-size: var(--typography-sem-cta-label-font-size);
    font-weight: var(--typography-sem-cta-label-font-weight);
    gap: var(--space-com-cta-margin);
    height: var(--size-sem-cta-height-medium);
    padding: 0 var(--space-com-cta-padding);
    text-decoration: none;

    &:visited,
    &:active,
    &:hover {
      color: var(--color-sem-icon-neutral-default);
      text-decoration: none;
    }
  }

  @media #{$medium} {
    margin-left: -20px;
    max-width: var(--wrapper-l);
    padding: var(--space-sem-padding-small) var(--space-sem-padding-medium);
    width: 100%;

    .affiliate-sticky-bar-left {
      align-items: center;
      flex-direction: row;
    }

    .affiliate-sticky-bar-right {
      flex: 0 0 auto;
    }

    .affiliate-sticky-bar-img {
      background-color: var(--color-ref-neutral-0);
      border: 1px solid var(--color-ref-neutral-300);
      display: flex;
      height: 100px;
      justify-content: center;
      overflow: hidden;
      padding: var(--space-sem-padding-2xsmall);
      width: 100px;

      img {
        height: 100%;
        overflow: hidden;
      }
    }

    .affiliate-sticky-bar-mob-btn {
      display: none;
    }

    .affiliate-sticky-bar-btn {
      display: flex;
    }

    .affiliate-sticky-bar-title {
      font-size: var(--text-typeset-font-size-l);
    }

    .affiliate-sticky-bar-price {
      font-size: var(--text-typeset-font-size-5xl);
    }
  }

  @media #{$xlarge} {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}
