@import '../../../../theme/breakpoints';

.skip-to-main-link {
  background-color: var(--color-sem-surface-neutral-inverted);
  color: var(--color-sem-surface-neutral-default);
  font-size: var(--text-typeset-font-size-m);
  font-weight: var(--text-typeset-font-weights-normal-700);
  left: -9999px;
  position: absolute;
  top: -9999px;

  &:focus {
    align-items: center;
    display: inline-flex;
    height: var(--size-ref-12);
    padding: 0 var(--space-sem-padding-medium);
    position: static;
  }

  &:focus-visible {
    outline: var(--border-width-sem-medium) solid var(--color-sem-focus-default);
    outline-offset: var(--space-sem-padding-2xsmall);
  }
}

.skip-to-main-wrapper {
  margin: 0 auto;
  max-width: var(--wrapper-xs);
  position: relative;
  z-index: 9998;

  &:focus-within {
    background-color: var(--color-sem-surface-neutral-inverted);
  }

  @media #{$medium} {
    margin-left: 0;
    max-width: var(--wrapper-l);
  }

  @media #{$xlarge} {
    margin-left: auto;
    max-width: var(--wrapper-xl);
  }
}
