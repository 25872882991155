@import '../../../../theme/breakpoints';

.secondary-footer {
  align-items: flex-start;
  column-gap: var(--space-ref-5);
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 auto;
  margin-left: 0;
  padding: 0;
  row-gap: var(--space-ref-5);

  li {
    &:not(:last-child) {
      border-right: 1px solid var(--color-sem-surface-neutral-subtle);
    }

    a {
      color: var(--color-ref-neutral-100) !important;
      font-size: var(--text-typeset-font-size-m) !important;
      font-weight: var(--text-typeset-font-weights-normal-600) !important;
      line-height: var(--text-typeset-line-heights-auto) !important;
      margin-right: var(--space-ref-5) !important;
      text-decoration: var(--text-typeset-text-decoration-none) !important;

      @media #{$medium} {
        &:hover {
          color: var(--white) !important;
          text-decoration: underline !important;
        }

        &:active {
          border: none;
        }
      }
    }
  }
}
