@import '../../../theme/breakpoints';

.main {
  background-color: var(--color-com-body-background);
  display: grid;
  height: auto;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: var(--wrapper-xs);
  padding-bottom: 10px;
  padding-left: var(--space-sem-padding-small);
  padding-right: var(--space-sem-padding-small);
  position: relative;

  @media #{$small} {
    border-left: 0;
    border-right: 0;
    max-width: calc(100vw - 2 * 20px);
    padding-top: 32px;
  }

  @media #{$medium} {
    border-right: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    margin-left: 0;
    margin-right: 0;
    max-width: var(--wrapper-l);
  }

  @media #{$large} {
    border-left: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    border-right: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    padding-left: var(--space-sem-padding-medium);
    padding-right: var(--space-sem-padding-medium);
  }

  @media #{$xlarge} {
    border-left: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    border-right: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle);
    margin: 0 auto;
    max-width: var(--wrapper-xl);
    padding-top: 36px;
  }
}
